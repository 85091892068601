import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "moment-timezone";
import Moment from "react-moment";
import { TailSpin } from "react-loader-spinner";
import { NavLink } from "react-router-dom";
import "./preojekte.scss";
import { ApiGet, ApiGetNoAuth } from "../../../helpers/API/ApiData";
import useDebounce from "../../../hooks/useDebounceHook";


export default function Projekte() {
  const [status, setStatus] = useState();
  const [leadData, setLeaddata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [count, setCount] = useState(0);
  function generateStatusData(maxTerminiert) {
    const statusData = [
     
      { value: "offen", label: "OFFEN" },
      {value:"NICHT ERREICHT",label:"NICHT ERREICHT"},
      { value: "AUFMAß", label: "AUFMAß" },
      { value: "ZEITLICHE VERZÖGERUNG", label: "ZEITLICHE VERZÖGERUNG" },
      { value: "ANGEBOTSBESPRECHUNG 2", label: "ANGEBOTSBESPRECHUNG 2" },
    ];
    const max = parseInt(maxTerminiert.match(/\d+/)[0]);
    for (let i = 1; i <= max; i++) {
      statusData.splice(1, 0, {
        value: `TERMINIERT (${i})`,
        label: `TERMINIERT (${i})`,
      });
    }

    return statusData;
  }
  const maxTerminiert =leadData&& leadData
  ?.map((item) => {
    if (item?.leadId?.status?.split(" ")[0] == "TERMINIERT") {
      return item?.leadId?.status;
    }
    return null; 
  }).filter(Boolean).sort((a, b) => parseInt(b.match(/\d+/)[0]) - parseInt(a.match(/\d+/)[0]))[0]; 
  
const finalTerminiertStatus = maxTerminiert|| "TERMINIERT (1)";



const [search,setSearch]=useState("")
const debouncedSearchValue = useDebounce(search, 900);
  const generatedStatusData = generateStatusData(finalTerminiertStatus);
 
  const handleStatus = (data) => {
    setStatus(data);
  };

  const getConfirmLeaddata = async () => {
    try {
      setLoading(true);
      let response = await ApiGet(
        `vattenfall/getConformLead?page=${page}&limit=${countPerPage}${
          status ? `&leadStatus=${encodeURIComponent(status?.value)}` : ""
        }${`&sortBy=leadId.status`}${`&sortOrder=-1`}${debouncedSearchValue?`&search=${search}`:""}`
      );
    

      setLeaddata(response?.data?.payload?.findConform);
      setCount(response?.data?.payload?.count);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getConfirmLeaddata();
  }, [status,page,count,search]);

  const columns = [
    {
      name: <span>No</span>,
      cell: (row, index) => {
        return (
          <p style={{ paddingLeft: "5px", paddingTop: "12px" }}>{index + 1}</p>
        );
      },
      width: "90px",
    },
    {
      name: <span>Kunde</span>,
      width: "200px",
      selector: (row) => (
        <div className="profileImage">
          {row.leadId ? row?.leadId?.name + " " + row?.leadId?.nachname : "-"}
        </div>
      ),
    },
    {
      name: <span>Adresse</span>,
      selector: (row) => (
        <div className="profileImage">
          {row?.leadId
            ? row?.leadId.strabe ?row?.leadId.strabe :"-" +
              " " +
              row?.leadId?.pLZ ?row?.leadId?.pLZ:"-" +
              " " +
              row?.leadId?.location?  row?.leadId?.location:"-"
            : "-"}
        </div>
      ),
    },
   
     {
      name: <span>Status</span>,
      width: "250px",
      selector: (row) => {
        const statusClass =
        ( row?.leadId?.status == "offen" || row?.leadId?.status=="NICHT ERREICHT" || row?.leadId?.status=="ZEITLICHE VERZÖGERUNG")
            ? "new-deisgnpppppppppp"
           :  row?.leadId?.status == "STORNO" ?"new-deisgn-statusooooooooooo" :"newstatusppppppppppp"
       

        return <div className={statusClass}>{ row?.leadId?.status ?row?.leadId?.status?.toUpperCase():"-"}</div>;
      },
    },
    {
      name: "",
      selector: (row) => (
        <div className="profileImage table-icon-alignment">
          <NavLink to={`/vattenlead?id=${row?.leadId?._id}`}>
            <div className="icon-svg">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M-0.0078125 13.2069V15.4998C-0.0078125 15.6325 0.044866 15.7596 0.138634 15.8534C0.232402 15.9472 0.359579 15.9998 0.492188 15.9998H2.79019C2.92254 15.9998 3.04948 15.9473 3.14319 15.8538L12.5912 6.40585L9.59119 3.40585L0.139188 12.8538C0.0453521 12.9474 -0.00751912 13.0744 -0.0078125 13.2069ZM10.8292 2.16685L13.8292 5.16685L15.2892 3.70685C15.4767 3.51932 15.582 3.26501 15.582 2.99985C15.582 2.73468 15.4767 2.48038 15.2892 2.29285L13.7042 0.706849C13.5167 0.519378 13.2624 0.414062 12.9972 0.414062C12.732 0.414062 12.4777 0.519378 12.2902 0.706849L10.8292 2.16685Z"
                  fill="black"
                />
              </svg>
            </div>
          </NavLink>
        </div>
      ),
    },
  ];

  const ProjectStyle = {
    control: (provided, state) => ({
      ...provided,
      maxWidth: "150px",
      fontSize: "12px",
      marginLeft: "30px",
      border: state.isFocused ? "2px solid #73AA85 !important" : "",
      boxShadow: "1px solid #73AA85 !important",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "13px",
      color: state.isSelected ? "white" : "black", // Change selected color here
      backgroundColor: state.isSelected ? "#73AA85" : "white",
      "&:hover": {
        backgroundColor: "#73AA85",
      },
    }),
    menu: (provided) => ({
      ...provided,
      minWidth: "220px",
      marginLeft: "30px",
      zIndex: "999",
    }),
  };
  const customNoDataComponent = () => (
    <div style={{ textAlign: "center", padding: "10px 0px", fontSize: "16px" }}>
      Aktuell sind keine Daten vorhanden.
    </div>
  );


  console.log("search",search)

  return (
    <>
      <div className="projekte-container">

        <div className="header-alignment-buttton">
        <div className="projekte-headerpart">
          <div className="projekte-title">
            <p>Projekte</p>
            <span></span>
          </div>

         

          <div className="status-selector">
            <Select
              className="dropdown-align-pp "
              value={status}
              name="userName"
              onChange={(e) => {
                handleStatus(e);
              }}
              placeholder="Status"
              options={generatedStatusData}
              isSearchable
              isClearable={true}
              styles={ProjectStyle}
            />
          </div>
        </div>

        <div className="projekte-title">
            <input type="text" placeholder="Suche" name="search"  value ={search} onChange={(e)=>setSearch(e.target.value)}/>
          </div>
        <div className="button-neeprojek"> <NavLink  to={'/addNewlead'}><button>neues Projekt erstellen</button></NavLink></div>
        </div>
        <div className="projekte-datatable">
          <DataTable
            columns={columns}
            data={leadData}
            responsive
            noDataComponent={customNoDataComponent()}
            //   customStyles={customStyles}
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[5, 10, 20, 25, 50]}
            paginationDefaultPage={page}
            progressPending={loading}
            progressComponent={
              <div className="LoadinComponent">
                <TailSpin color="#334D52" height={30} width={30} />
              </div>
            }
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        </div>
      </div>
    </>
  );
}
